import styled from '@emotion/styled'

import { AlphaColors, FontSizes, FontStyles } from '../../utils/styles'

const Paragraph = styled.p(props => ({
  ...FontStyles.PARAGRAPH_NORMAL,

  // Add large prop for larger fontsize
  fontSize: props.large ? FontSizes.LARGE : FontSizes.BASE,

  // Color prop allows for quick access to changing text color
  color: AlphaColors.BLACK_80,
  ...props.style,
}))

export { Paragraph as default }

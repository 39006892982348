import React from 'react'
import styled from '@emotion/styled'

import Section from '../Section'
import { GridContainer, ContentContainer } from '../Grid'
import { H2, Paragraph } from '../Typography'
import ButtonLink from '../Buttons'
import ButtonShare from '../Buttons/ButtonShare'

import assets from '../../content/assets'
import { Colors, Gutter, MediaQueries } from '../../utils/styles'

const TextContainer = styled.div({
  marginBottom: Gutter.FULL,
})

const ButtonContainer = styled.div`
  display: flex;
  @media (max-width: ${MediaQueries.MOBILE}) {
    flex-direction: column;
    justify-content: flex-start;
  }
`

const ButtonExternalContainer = styled.div`
  margin-left: ${Gutter.FULL};
  @media (max-width: ${MediaQueries.MOBILE}) {
    margin-left: 0;
    margin-top: ${Gutter.FULL};
  }
`

const Header = styled(H2)({
  color: Colors.WHITE,
})

const Message = styled(Paragraph)({
  color: Colors.WHITE,
})

const RequestDemoCtaBlock = () => (
  <Section backgroundImage={assets.images.fluidBubblesPrimary1Blur}>
    <GridContainer>
      <ContentContainer width={[1, 1, 1 / 2]} style={{ padding: Gutter.FULL }}>
        <TextContainer>
          <Header>Want to learn more?</Header>
          <Message large>
            Contact our team to schedule a demo and learn how Pulse Analytics can improve your
            market access strategies and execution.
          </Message>
        </TextContainer>
        <ButtonContainer>
          <ButtonLink
            buttonColor={Colors.WHITE}
            textColor={Colors.PRIMARY}
            to="/request-demo/"
            id="button--cta__request-demo"
            style={{
              width: 'min-content',
            }}
          >
            Request Demo
          </ButtonLink>
          <ButtonExternalContainer>
            <ButtonShare />
          </ButtonExternalContainer>
        </ButtonContainer>
      </ContentContainer>
    </GridContainer>
  </Section>
)

export default RequestDemoCtaBlock

import React from 'react'
import ButtonExternalLink from './ButtonExternalLink'
import { Colors } from '../../utils/styles'

const ButtonShare = () => (
  <ButtonExternalLink
    to="mailto:?subject=Decision Support Tools for Market Access and Key Accounts in Oncology and Specialty Therapeutics&body=Pulse Analytics provides market access and provider key account teams with strategic decision support tools that span priority access stakeholders and influencers to support engagement planning across Payer Quality of Access, Provider Key Accounts, and Value-Based Models (Oncology Pathways and Alternative Payment Models).%0D%0A%0D%0APlease visit our site to explore our offerings and request a free demo: https://www.pulseanalyticsapp.com" // eslint-disable-line
    buttonColor={Colors.WHITE}
    textColor={Colors.PRIMARY}
    secondary
  >
    Share with Colleague
  </ButtonExternalLink>
)

export default ButtonShare
